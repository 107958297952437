<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="250px">

        <!-- 第三步 -->
        <div>
            <el-form-item label="作品创作性质" prop="creationNature">
            <el-select
                v-model="creationNatureText"
                placeholder="请选择作品创作性质"
                @change="selectCreationNature"
            >
                <el-option
                v-for="(item, index) in constants.creationNature"
                :key="index"
                :label="item.label"
                :value="index"
                >
                </el-option>
            </el-select>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '1'">
                <div slot="description">
                作者未参考或使用已存在作品创作的作品。
                </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '2'">
                <div slot="description">
                作者参考已存在作品衍生创作的作品。
                </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '3'">
                <div slot="description">
                作者参考或使用已存在的外语作品衍生创作的作品。
                </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '4'">
                <div slot="description">
                作者参考或使用已存在作品衍生创作的系列。
                </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '5'">
                <div slot="description">
                作者使用已存在作品衍生创作的作品。
                </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '6'">
                <div slot="description">
                作者参考或使用已存在作品衍生创作的合集。
                </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.creationNature == '7'">
                <div slot="description">
                请添加作品性质说明。
                </div>
            </a-alert>
            
            </el-form-item>
            <el-form-item v-if="form.creationNature == '7'" label="作品性质说明" prop="creationPropertyDesc.text">
            <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入作品性质说明"
                v-model="form.creationPropertyDesc.text"
            ></el-input>
            </el-form-item>
            <el-form-item label="内容简介" prop="creationIntention.text" style="margin-bottom: 50px;">
            <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入内容简介"
                v-model="form.creationIntention.text"
            ></el-input>
            <a-alert class="tips" type="warning">
                <div slot="message">温馨提示：</div>
                <div slot="description">
                请填写中心内容及作品特点：对作品进行简述，包括主要内容、章节编排、部分划分、与同类作品相比的独特之处等。如美术作品需对作品本身的表现形式和审美特征进行描述（颜色、形态、表情等）。(注：若提交的作品样本中含有外文请在内容简介中附中文解释。)
                </div>
            </a-alert>
            </el-form-item>
            <el-form-item label="创作过程" prop="creationOriginality.text">
            <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入创作过程"
                v-model="form.creationOriginality.text"
            ></el-input>
            <a-alert class="tips" type="warning">
                <div slot="message">温馨提示：</div>
                <div slot="description">
                (1)介绍著作权人。个人：需交代工作单位相关情况，无工作写明“无工作单位，现从事……”。单位：写明单位基本情况。（2）创作过程。完整介绍创作意图、策划准备、开始时间、修改情况及内容、创作方式（手绘、电脑软件等）、页数字数、完成时间。（3）介绍使用情况：发表情况、是否许可他人使用（摄影作品写明设备型号、光圈、快门等具体数值）。如作品有参考引用他人作品需交代说明。
                </div>
            </a-alert>
            </el-form-item>
            <el-form-item label="创作完成时间" prop="finishTime">
            <el-date-picker
                v-model="form.finishTime"
                placeholder="选择日期时间"
                type="date"
                format="yyyy-MM-dd"
                value-format="timestamp"
                :picker-options="pickerOptionsStart"
            >
            </el-date-picker>
            </el-form-item>
            <el-form-item label="创作完成地点" prop="province">
              <china-area-data :areaData="areaData1" @change="selectArea"></china-area-data>
            </el-form-item>
            <el-form-item label="作品发表状态" prop="publishStatus">
            <el-select
                v-model="publishStatusText"
                placeholder="请选择作品发表状态"
                @change="selectPublishStatus"
            >
                <el-option
                v-for="(item, value) in constants.publishStatus"
                :key="value"
                :label="item.label"
                :value="value"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="首次发表日期" prop="publishTime" v-if="form.publishStatus == 2">
            <el-date-picker
                v-model="form.publishTime"
                placeholder="选择日期时间"
                type="date"
                format="yyyy-MM-dd"
                value-format="timestamp"
                :picker-options="pickerOptionsEnd"
            >
            </el-date-picker>
            </el-form-item>
            <el-form-item label="发表地点" prop="province2" v-if="form.publishStatus == 2">
            <china-area-data :areaData="areaData2" @change="selectArea2"></china-area-data>
            </el-form-item>
            <el-form-item class="submitRow">
            <el-button type="primary" @click="prev">上一步</el-button>
            <el-button type="primary" @click="next">下一步</el-button>
            </el-form-item>
        </div>
    </el-form>
  </div>
</template>

<script>
import { queryExistOrderItems } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
import multipleSelect from "../../../../components/form/multipleSelect";
import choosePersonDialog from "../../../../components/form/choosePersonDialog";
import resultView from "../components/resultView";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../../mixins";
import constants from "@/utils/constants";


export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    multipleSelect,
    choosePersonDialog,
    resultView,
    ChinaAreaData,
  },
  props: {
    stepForm: Object,
  },
  data() {
    return {
      constants,
      step: 1,
      tabIdx: "tab1",
      creationNatureText: '',
      publishStatusText: '',

      form: {
        creationNature: "",
        creationPropertyDesc:{
          text:"",
        },
        finishTime: "",
        creationIntention:{
          text:"",
        },
        creationOriginality:{
          text:"",
        },
        province: "",
        city: "",
        publishStatus: "",
        province2: "",
        city2: "",
        publishTime: "",

      },
      rules: {
        creationNature:[
          { required: true, message: "请选择作品创作性质", trigger: "blur" },
        ],
        finishTime: [
          { required: true, message: "请选择创作完成时间", trigger: "blur" },
        ],
        'creationIntention.text': [
          { required: true, message: "请输入内容简介", trigger: "blur" },
        ],
        'creationOriginality.text': [
          { required: true, message: "请输入创作过程", trigger: "blur" },
        ],
        province: [
          { required: true, validator: this.valiCity, trigger: "blur" },
        ],
        publishStatus: [
          { required: true, message: "请选择作品发表状态", trigger: "blur" },
        ],
        publishTime: [
          { required: true, message: "请选择首次发表日期", trigger: "blur" },
        ],
        province2: [
          { required: true, validator: this.valiCity2, trigger: "blur" },
        ],

      },
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,

      //完成日期在发表日期之前,同时不能超过当前日期
      pickerOptionsStart: {
          disabledDate: time => {
              return time.getTime() > new Date(this.form.publishTime).getTime() || time.getTime() > Date.now()   //-8.64e7可以让开始时间和结束时间选同一天
          }
      },
      pickerOptionsEnd: {
          disabledDate: time => {
              return time.getTime() < new Date(this.form.finishTime).getTime() || time.getTime() > Date.now()     //-8.64e7可以让开始时间和结束时间选同一天
          }
      },
    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  created() {
    //this.loadData();
    this.form = this.stepForm
    if(this.form.creationNature){
      this.creationNatureText = this.constants.creationNature[this.form.creationNature * 1 - 1].label;
    }else{
      this.form.creationNature = '1';
      this.creationNatureText = '原创';
    }

    if(this.form.publishStatus){
      this.publishStatusText = this.constants.publishStatus[this.form.publishStatus * 1 - 1].label;
    }else{
      this.form.publishStatus = '1';
      this.publishStatusText = '未发表';
    }


    this.areaData1 = {
      province: this.form.province,
      city: this.form.city,
    },
    this.areaData2 = {
      province: this.form.province2,
      city: this.form.city2,
    }
    if(!this.form.province){
      this.form.province = '湖北省'
    }
    if(!this.form.city){
      this.form.city = '武汉市'
    }
    if(!this.form.province2){
      this.form.province2 = '湖北省'
    }
    if(!this.form.city2){
      this.form.city2 = '武汉市'
    }
    
  },
  methods: {
    loadData() {
      queryExistOrderItems().then(({ data }) => {
        this.fileList = data;
      });
      queryObligeeList(1, 100).then(({ data }) => {
        this.obligees = data.list;
      });
    },
    calAmount() {
      let amount = 0;
      let registerConfig = this.evidencePaymentPrice.register;
      amount = registerConfig.amount * 1;

      this.form.orderAmount = amount;
    },
    async onSubmit() {
      console.log("onSubmit..");
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        this.mixinsScrollView();
        return;
      }
      this.setTabIdx("tab2");
    },
    setTabIdx(tabIdx) {
      this.tabIdx = tabIdx;
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
    },
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      // if (!this.form.area) {
      //   callback(new Error("请选择区县"));
      //   return;
      // }
      callback();
    },
    valiCity2(rule, value, callback) {
      if (!this.form.province2) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city2) {
        callback(new Error("请选择城市"));
        return;
      }
      callback();
    },
    selectArea(data) {
      // console.log(data);
      if(data.laber == 'province'){
        this.form.province = data.value;
      }else if(data.laber == 'city'){
        this.form.city = data.value;
      }
      // console.log(this.form.province);
      // this.form.area = data.selectData.area.name;
    },
    selectArea2(data) {
      if(data.laber == 'province'){
        this.form.province2 = data.value;
      }else if(data.laber == 'city'){
        this.form.city2 = data.value;
      }
      // this.form.area2 = data.selectData.area.name;
    },
    chooseFile() {
      let files = [];
      for (let i = 0; i < this.form.evidenceFile.length; i++) {
        const element = this.form.evidenceFile[i];
        files.push(this.fileList[element * 1]);
      }
      this.form.files = files;
      this.calAmount();
    },

    selectCreationNature(index){
      this.creationNatureText = this.constants.creationNature[index].label;
      this.form.creationNature = this.constants.creationNature[index].value;
    },

    selectPublishStatus(index){
      this.publishStatusText = this.constants.publishStatus[index].label;
      this.form.publishStatus = this.constants.publishStatus[index].value;
    },


    prev(){
        this.$emit('getnext',2)
    },
    async next(){
        try {
          const formBaseResult = await this.$refs["form"].validate();
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          this.mixinsScrollView();
          return;
        }
        this.$emit('getnext',4,this.form)
    },

  },
};
</script>

<style lang='scss' scoped>
@import "../../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
}
.ownershipRow {
  margin-bottom: 0;
}
.ownershipWrap {
  position: relative;
  padding-top: 40px;
  /deep/ .el-radio-group {
    position: absolute;
    width: 200%;
    left: 0;
    top: 11px;
  }
  .tips {
    margin-top: 0;
    .red {
      color: #ff4d4f;
      vertical-align: middle;
    }
  }
}
// .tips {
//   margin-top: 20px;
// }
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}
.signatureName {
  .el-select,
  .el-input {
    width: calc(110% / 2.4);
  }
  .el-select {
    margin-right: calc(20% / 2.4);
  }
}
.Uploader2 {
  font-size: 14px;
  height: 100%;
  text-align: center;
  background: #f9fafc;
  border-radius: 4px;
  border: 1px solid #c0ccda;
}
/deep/ .submitRow .el-form-item__content {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MainStepsForm .submitRow button {
  width: 46% !important;
}

.stepTitle{
  width: 100%;
  margin: 50px 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepTitle li {
  padding: 0 10px;
}
.stepTitle li span{
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 18, 22, 0.2);
  font-size: 16px;
  color: rgba(0, 18, 22, 0.2);

}
.stepTitle li p{
  color: rgba(0, 18, 22, 0.45);
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.stepTitle li.active span{
  border-color: #007DFF;
  color: #fff;
  background: #007DFF;
}
.stepTitle li.active p{
  color: #007DFF;
}
</style>
