<template>
  <div class="container resultView">
    <div>
      <span class="back" @click="back">
        <i class="el-icon-arrow-left"></i> 返回
      </span>
    </div>
    <div class="commonWhiteBg">
      <div class="commonTitle">提交预览</div>
      <div class="MainStepsForm">
        <div class="formWrap">
          <el-form ref="form" label-width="160px">
            <table>
              <tr>
                <td>
                  <el-form-item label="登记文件" prop="evidenceFile">
                    <span v-for="(file, index) in orderInfo.files" :key="index"
                      >{{ file.sourceName }}
                    </span>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item label="登记名称" prop="subject">
                    {{ orderInfo.subject }}
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <div v-for="(item,index) in orderInfo.authors" :key="index">
                    <el-form-item :label="'作者'+(index+1)" prop="name">
                      <span style="margin-right: 20px;">{{item.name}}</span>
                      <span v-if="item.signatureType == '1'">(本名: {{item.signatureName}})</span>
                      <span v-if="item.signatureType == '2'">(别名: {{item.signatureName}})</span>
                    </el-form-item>
                    <!-- <el-form-item label="署名" prop="signatureName">
                      <span>{{item.signatureName}}</span>
                    </el-form-item> -->
                  </div>
                </td>
                <td>
                  <el-form-item label="著作权人" prop="right">
                    <span>{{orderInfo.right[0].userName}}</span>
                    <!-- <span v-for="(author, index) in orderInfo.right" :key="index">
                      {{author.obligeeType == "1" ? author.userName : author.companyName}}
                      <template v-if="index < orderInfo.right.length - 1">,</template>
                    </span> -->
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <el-form-item label="作品类型" prop="worksType" v-if="orderInfo.worksType">
                    {{ constants.worksType[orderInfo.worksType * 1-1].label }}
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    label="权利权属"
                    prop="ownership"
                    class="ownershipRow"
                    v-if="orderInfo.ownership"
                  >
                    {{ constants.ownership[orderInfo.ownership * 1-1].label }}
                  </el-form-item>
                </td>
              </tr>
              <tr v-if="orderInfo.ownershipPicture.additionalProp3">
                <td colspan="2">
                  <el-form-item v-if="orderInfo.ownershipPicture.additionalProp3" label="权利归属证明材料" prop="ownershipPicture.additionalProp3">
                    <el-upload class="Uploader2 fl" action="" :disabled="true">
                      <!-- <img v-if="orderInfo.ownershipPicture.additionalProp3" :src="orderInfo.ownershipPicture.additionalProp3" class="avatar"> -->
                      <el-image
                        class="img"
                        v-if="orderInfo.ownershipPicture.additionalProp3"
                        :src="orderInfo.ownershipPicture.additionalProp3"
                        :preview-src-list="[orderInfo.ownershipPicture.additionalProp3]"
                      >
                      </el-image>
                    </el-upload>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td :colspan="orderInfo.obtainType == '4'? '':'2'">
                  <el-form-item label="权利取得方式" prop="obtainType" v-if="orderInfo.obtainType" :style="orderInfo.obtainType == '4'? 'height: 120px;':''">
                    {{ constants.obtainType[orderInfo.obtainType * 1-1].label }}
                  </el-form-item>
                </td>
                <td v-if="orderInfo.obtainType == '4'">
                  <el-form-item v-if="orderInfo.obtainType == '4'" label="其他权利获得方式说明" prop="obtainTypeDesc.text">
                    <el-input
                      v-model="orderInfo.obtainTypeDesc.text"
                      :rows="5"
                      disabled
                      type="textarea"
                      :style="orderInfo.obtainType == '4'? 'height: 120px;':''"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td :colspan="orderInfo.ownershipPicture.additionalProp1? '':'2'">
                  <el-form-item v-if="orderInfo.ownershipPicture.additionalProp4" label="权利保证书" prop="ownershipPicture.additionalProp4">
                    <el-upload class="Uploader2 fl" action="" :disabled="true">
                      <!-- <img v-if="orderInfo.ownershipPicture.additionalProp4" :src="orderInfo.ownershipPicture.additionalProp4" class="avatar"> -->
                      <el-image
                        class="img"
                        v-if="orderInfo.ownershipPicture.additionalProp4"
                        :src="orderInfo.ownershipPicture.additionalProp4"
                        :preview-src-list="[orderInfo.ownershipPicture.additionalProp4]"
                      >
                      </el-image>
                    </el-upload>
                  </el-form-item>
                </td>
                <td v-if="orderInfo.ownershipPicture.additionalProp1">
                  <el-form-item v-if="orderInfo.ownershipPicture.additionalProp1" label="权利获得方式证明材料" prop="ownershipPicture.additionalProp1">
                    <el-upload class="Uploader2 fl" action="" :disabled="true">
                      <!-- <img v-if="orderInfo.ownershipPicture.additionalProp1" :src="orderInfo.ownershipPicture.additionalProp1" class="avatar"> -->
                      <el-image
                        class="img"
                        v-if="orderInfo.ownershipPicture.additionalProp1"
                        :src="orderInfo.ownershipPicture.additionalProp1"
                        :preview-src-list="[orderInfo.ownershipPicture.additionalProp1]"
                      >
                      </el-image>
                    </el-upload>
                    <el-upload v-if="orderInfo.ownershipPicture.additionalProp2" class="Uploader2 fl" action="" :disabled="true">
                      <!-- <img v-if="orderInfo.ownershipPicture.additionalProp2" :src="orderInfo.ownershipPicture.additionalProp2" class="avatar"> -->
                      <el-image
                        class="img"
                        v-if="orderInfo.ownershipPicture.additionalProp2"
                        :src="orderInfo.ownershipPicture.additionalProp2"
                        :preview-src-list="[orderInfo.ownershipPicture.additionalProp2]"
                      >
                      </el-image>
                    </el-upload>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <el-form-item label="权益拥有情况" prop="rightHave">
                    <multiple-select
                      v-model="orderInfo.rightHave"
                      :isResultView="true"
                      :listOpt="constants.right"
                    ></multiple-select>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td :colspan="orderInfo.creationNature == '7'? '':'2'">
                  <el-form-item label="创作性质" prop="creationNature" v-if="orderInfo.creationNature">
                    {{ constants.creationNature[orderInfo.creationNature * 1-1].label }}
                  </el-form-item>
                </td>
                <td v-if="orderInfo.creationNature == '7'">
                  <el-form-item v-if="orderInfo.creationNature == '7'" label="作品性质说明" prop="creationPropertyDesc.text">
                    <el-input
                      v-model="orderInfo.creationPropertyDesc.text"
                      :rows="5"
                      disabled
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <el-form-item label="内容简介" prop="creationIntention.text">
                    <el-input
                      v-model="orderInfo.creationIntention.text"
                      :rows="5"
                      disabled
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item label="创作过程" prop="creationOriginality.text">
                    <el-input
                      v-model="orderInfo.creationOriginality.text"
                      :rows="5"
                      disabled
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <el-form-item label="创作完成时间" prop="finishTime">
                    {{ orderInfo.finishTimeText }}
                  </el-form-item>
                </td>
                <td>
                  <el-form-item label="创作完成地点" prop="adress">
                    {{ orderInfo.province}}-{{ orderInfo.city}}
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <el-form-item label="作品发表状态" prop="publishStatus">
                    {{ orderInfo.publishStatusText }}
                  </el-form-item>
                </td>
              </tr>
              <tr v-if="orderInfo.publishStatus == 2">
                <td>
                  <el-form-item v-if="orderInfo.publishStatus == 2" label="首次发表时间" prop="publishTime">
                    {{ orderInfo.publishTimeText }}
                  </el-form-item>
                </td>
                <td>
                  <el-form-item v-if="orderInfo.publishStatus == 2" label="发表地点" prop="adress">
                    {{ orderInfo.province2}}-{{ orderInfo.city2}}
                  </el-form-item>
                </td>
              </tr>
            </table>
            <!-- <el-form-item label="创作意图" prop="creationOriginality.text">
                  <el-input
                    v-model="orderInfo.creationOriginality.text"
                    :rows="5"
                    disabled
                    type="textarea"
                  ></el-input>
                </el-form-item> -->
            <el-form-item
              label="支付方式"
              prop="payStyle"
              class="choosePayStyle"
            >
              <el-radio-group v-model="orderInfo.paymode">
                <el-radio label="vip" v-if="orderInfo.paymode == 'vip'">
                  <span class="onlyText"
                    >中国知链会员权益支付（当前存证次数可用{{
                      vipInfo ? vipInfo.registerCount : 0
                    }}次）</span
                  >
                </el-radio>
                <el-radio label="alipay" v-if="orderInfo.paymode == 'alipay'">
                  <i class="iconfont aliPayColor"> &#xe675; </i>
                  <span>支付宝支付</span></el-radio
                >
                <el-radio label="wechat" v-if="orderInfo.paymode == 'wechat'">
                  <i class="iconfont weChatColor"> &#xe64f; </i>
                  <span>微信支付</span></el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="支付金额" v-if="orderInfo.paymode != 'vip'" class="payMount">
              <div>
                <em class="bigPrice"> {{ orderInfo.orderAmount }} </em> 元
              </div>
            </el-form-item>
            <el-form-item class="submitRow">
              <el-button type="primary" @click="onGoPay">确认并支付</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <pay-dialog ref="payDialog" payForm="register" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import multipleSelect from "../../../../components/form/multipleSelect";
import PayDialog from "../../../../components/PayDialog";
import {
  queryExistOrderItems,
  applyRegisterOrder,
} from "@/api/EvidenceService";
import { mapGetters } from "vuex";
import constants from "@/utils/constants";
export default {
  name: "registerResultView",
  components: { multipleSelect, PayDialog },
  props: {
    orderInfo: Object,
  },
  data() {
    return {
      constants,
    };
  },
  created() {
    this.init();
    this.orderInfo.finishTimeText = this.dayjs(
      this.orderInfo.finishTime
    ).format("YYYY-MM-DD");
    this.orderInfo.publishTimeText = this.dayjs(
      this.orderInfo.publishTime
    ).format("YYYY-MM-DD");
    if(this.orderInfo.publishStatus=='1'){
      this.orderInfo.publishStatusText = '未发表'
    }else if(this.orderInfo.publishStatus=='2'){
      this.orderInfo.publishStatusText = '已发表'
    }
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  methods: {
    init() {
      console.log("init result ..");
    },
    back() {
      this.$emit("set-tab-idx", "tab1");
      this.$emit('getnext',1)
    },
    onGoPay() {
      let that = this;
      if (that.order) { // 如果已经提交过订单了，就阻止重复提交
        that.$refs.payDialog.showDialog(that.order);
        that.isPayDialogVisible = true;
        return false;
      }
      let params = {
        subject: that.orderInfo.subject,
        paymode: that.orderInfo.paymode,
        belong: that.orderInfo.ownership,
        // authors: that.orderInfo.author.map((t) => {
        //   return t.obligeeCode;
        // }),
        authors: that.orderInfo.authors,
        obligee: [that.orderInfo.right[0].obligeeCode],
        // obligee: that.orderInfo.right.map((t) => {
        //   return t.obligeeCode;
        // }),
        worksType: that.orderInfo.worksType,
        completeTime: Math.round(new Date(that.orderInfo.finishTime).getTime() / 1000),
        publishTime: Math.round(new Date(that.orderInfo.publishTime).getTime() / 1000),
        completeLocation: {
          province: that.orderInfo.province,
          city: that.orderInfo.city,
        },
        publishLocation: {
          province: that.orderInfo.province2,
          city: that.orderInfo.city2,
        },
        publishStatus: that.orderInfo.publishStatus,
        copyrights: that.orderInfo.rightHave,
        creationIntention: that.orderInfo.creationIntention,
        creationProperty: that.orderInfo.creationNature,
        creationOriginality: that.orderInfo.creationOriginality,
        creationPropertyDesc: that.orderInfo.creationPropertyDesc,
        items: that.orderInfo.files,
        obtainType: that.orderInfo.obtainType,
        obtainTypeDesc: that.orderInfo.obtainTypeDesc,
        ownershipPicture: that.orderInfo.ownershipPicture,
        signatureType: that.orderInfo.signatureType,
      };
      console.log(params);
      applyRegisterOrder(params).then(({ data }) => {
        that.order = data;
        console.log("order::::::::::::",that.order);
        if (data.paymentStatus == "21") {
          that.bus.$emit("refreshVip");
          that.onSetPayDialogClose(true);
        } else {
          that.$refs.payDialog.showDialog(data);
          that.isPayDialogVisible = true;
        }
      });
    },
    onSetPayDialogClose(result) {
      console.log(result);
      if (result) {
        //跳转至详情页
        this.$router.push({
          path: "/Register/Detail",
          query: { orderNo: this.order.orderNo },
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../../../assets/css/inputForm.scss";
.Uploader2, .Uploader3{
    font-size: 14px;
    width: 92px;
    height: 120px;
    text-align: center;
    background: #f9fafc;
    border-radius: 4px;
    border: 1px solid #c0ccda;
    position: relative;
    display: inline-block;
    margin-right: 20px;
}
.Uploader2 img, .Uploader3 img ,.Uploader2 .img, .Uploader3 .img {
    width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.el-textarea{
  height: 120px;
}
.MainStepsForm .formWrap{width: 100%;padding: 0 20px;}
.MainStepsForm .formWrap table{width: 100%;margin-bottom: 20px;}
.MainStepsForm .formWrap table tr td{width: 50%;border: 1px solid #333;padding: 0;min-height:40px;}
.el-form-item {margin-bottom: 0;}
.Uploader2, .Uploader3{margin-top: 10px;margin-bottom: 10px;}
/deep/ .MainStepsForm .submitRow .el-button--primary,.submitRow .el-form-item__content{width: 50%;display: block!important;}
/deep/ .MainStepsForm .formWrap table tr td .el-form-item{height: 100%;}
/deep/ .MainStepsForm .formWrap table tr td .el-form-item__label{font-size: 14px!important;color: #999!important;font-weight: normal!important;text-align: left!important;padding: 0 10px;}
/deep/ .MainStepsForm .formWrap table tr td .el-form-item__content{border-left: 1px solid #333;padding: 0 10px;height: 100%;}
/deep/ .el-textarea.is-disabled .el-textarea__inner{border: none;background: transparent;min-height: 0!important;padding: 5px 0;}
.submitRow /deep/.el-form-item__content{margin-left: 0!important;}

@media screen and (max-width: 991px){
  .MainStepsForm .commonWhiteBg{padding: 20px;}
  .MainStepsForm .formWrap{padding: 0;}
  /deep/.el-form-item__label{width: 80px!important;}
  /deep/.el-form-item__content{margin-left: 80px!important;}
  .choosePayStyle /deep/.el-form-item__label,.payMount /deep/.el-form-item__label{width: 100px!important;}
}

</style>
