<template>
  <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="250px">

            <!-- 第五步 -->
            <div>
              <el-form-item
                label="权利权属"
                prop="ownership"
              >
              <el-select
                  v-model="ownershipText"
                  @change="chooseOwnership"
                >
                  <el-option
                    v-for="(item, index) in constants.ownershipOpt"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  >
                  </el-option>
                </el-select>
                <a-alert class="tips" type="warning" v-if="form.ownership == '1'">
                  <div slot="description" >
                    个人作品：自然人在没有他人的参与下，独立创作的作品。
                  </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.ownership == '2'">
                  <div slot="description" >
                    合作作品：两人以上合作创作的作品。
                  </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.ownership == '3'">      
                  <div slot="description" >
                    法人作品：由法人或者其他组织主持，代表法人或者其他组织意志创作，并由法人或者其他组织承担责任的作品，法人或者其他组织视为作者。
                  </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.ownership == '4'">
                  <div slot="description" >
                    职务作品：公民为完成法人或者其他组织工作任务所创作的作品。
                  </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.ownership == '5'">
                  <div slot="description" >
                    委托作品：委托人委托其他人创作的作品。
                  </div>
                  
                  
                </a-alert>
              </el-form-item>

              <el-form-item class="ownershipPicture1"
                label="权利归属证明材料"
                prop="ownershipPicture.additionalProp3"
                :rules="{ required: true, validator: valiIcon3, trigger: 'blur' }"
                v-if="form.ownership == '2' || form.ownership == '3' || form.ownership == '4' || form.ownership == '5'"
              >
              <div class="uploadBusinessLicense" >
                
                  <el-upload class="Uploader2 fl"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess3"
                    :before-upload="uploadBefore3">
                      <img v-if="form.ownershipPicture.additionalProp3" :src="form.ownershipPicture.additionalProp3" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <div class="clear"></div>
              </div>
                <a-alert class="tips" type="warning">
                  <div slot="message">温馨提示：</div>
                  <div slot="description" v-if="form.ownership == '2'">
                    <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql1.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql1.docx"
                      >合作作品权属协议书附件</a
                    >附件仅作参考，已有合同需明确著作权权属。
                  </div>
                  <div slot="description" v-if="form.ownership == '3'">
                    <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql2.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql2.docx"
                      >著作权权属申明附件</a
                    >附件仅作参考，已有合同需明确著作权权属。
                  </div>
                  <div slot="description" v-if="form.ownership == '4'">
                    <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql3.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql3.docx"
                      >职务作品权属约定附件</a
                    >附件仅作参考，已有合同需明确著作权权属。
                  </div>
                  <div slot="description" v-if="form.ownership == '5'">
                    <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql4.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql4.docx"
                      >委托创作协议书附件(甲方享有全部权利)</a
                    >,<a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql5.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql5.docx"
                      >委托创作协议书附件(作者保留署名权)</a
                    >附件仅作参考，已有合同需明确著作权权属。
                  </div>
                </a-alert>
              </el-form-item>

              <div v-for="(item,index) in form.authors" :key="index">
                <el-form-item label="作者名称" :prop="'authors.'+index+'.name'" :rules="{ required: true, message: '请输入作者名称', trigger: 'blur' }">
                  <el-input placeholder="请输入作者名称" v-model="item.name"></el-input>
                </el-form-item>
                <el-form-item label="署名" :prop="'authors.'+index+'.signatureName'" class="signatureName" :rules="{ required: true, message: '请输入署名', trigger: 'blur' }">
                  <el-select v-model="signatureTypeText[index]" @change="(val)=>chooseSignatureType(index,val)">
                    <el-option
                      v-for="(item, index) in constants.signatureType"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                  <el-input v-if="item.signatureType != '3'" v-model="item.signatureName"></el-input>
                </el-form-item>
                <el-form-item v-if="form.ownership == '2' || form.ownership == '4' || form.ownership == '5'">
                  <el-button type="primary" v-if="index+1 == form.authors.length" @click="addAuthors(index)">添加作者</el-button>
                  <el-button type="primary" v-if="index !== 0" @click="delAuthors(index)">删除作者</el-button>
                </el-form-item>
              </div>
              <el-form-item label="权利取得方式" prop="obtainType">
                <el-select
                  v-model="obtainTypeText"
                  placeholder="请选择权利取得方式"
                  @change="chooseObtainType"
                >
                  <el-option
                    v-for="(item, index) in constants.obtainType"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              
              <el-form-item
                label="权利保证书"
                prop="ownershipPicture.additionalProp4"
                :rules="{ required: true, validator: valiIcon4, trigger: 'blur' }"
              >
              <div class="uploadBusinessLicense" >
                   <!-- <el-upload class="Uploader2 fl"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess4"
                        :before-upload="uploadBefore4">
                          <img v-if="form.ownershipPicture.additionalProp4" :src="form.ownershipPicture.additionalProp4" class="avatar">
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
                      <el-checkbox v-model="ownershipChecked" @change="ownershipChange"></el-checkbox> 我已阅读并同意<a href="javascript:;" @click="openViewer">《作品登记权利保证书》</a>
                      <el-image-viewer
                        v-if="isOpenViewer"
                        :on-close="closeViewer"
                        :url-list="[require('../../../../assets/images/evidenceRight.png')]"
                      />
                      <div class="clear"></div>
                  </div>
                <a-alert class="tips" type="warning">
                  <div slot="message">温馨提示：</div>
                  <div slot="description">
                    <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql7.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql7.docx"
                      >权利保证书模板</a
                    >请上传权利保证书(作品)，须亲笔签名或盖章确认。
                  </div>
                </a-alert>
              </el-form-item>
              
              <el-form-item
                label="权利取得方式证明材料"
                prop="ownershipPicture.additionalProp1"
                :rules="{ required: true, validator: valiIcon1, trigger: 'blur' }"
                v-if="form.obtainType == '2' || form.obtainType == '3' || form.obtainType == '4' || form.obtainType == '5'"
              >
              <div class="uploadBusinessLicense" >
                <el-upload class="Uploader2 fl"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess1"
								 :before-upload="uploadBefore1">
									<img v-if="form.ownershipPicture.additionalProp1" :src="form.ownershipPicture.additionalProp1" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<div class="clear"></div>
							</div>
                <a-alert class="tips" type="warning">
                  <div slot="message">温馨提示：</div>
                  <div slot="description">
                    <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql6.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql6.docx"
                      >权利取得方式证明材料附件</a
                    >点击下载后，加盖单位公章，法定代表人签字后拍照上传。
       
                  </div>
                </a-alert>
              </el-form-item>

              <el-form-item v-if="form.obtainType == '4'" label="其他权利获得方式说明" prop="obtainTypeDesc.text">
                <el-input
                  type="textarea"
                  :rows="6"
                  placeholder="请输入其他权利获得方式说明"
                  v-model="form.obtainTypeDesc.text"
                ></el-input>
              </el-form-item>
               
              <el-form-item label="权益拥有情况" prop="rightHave">
                <multiple-select
                  v-model="form.rightHave"
                  :listOpt="constants.right"
                ></multiple-select>
              </el-form-item>
              <!-- <el-form-item
                label="其它证明材料"
                prop="ownershipPicture.additionalProp2"
                v-if="form.obtainType == '2' || form.obtainType == '3' || form.obtainType == '4' || form.obtainType == '5'"
              >
              <div class="uploadBusinessLicense" >
								<el-upload class="Uploader3 fl"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess2"
								 :before-upload="uploadBefore2">
									<img v-if="form.ownershipPicture.additionalProp2" :src="form.ownershipPicture.additionalProp2" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<div class="clear"></div>
							</div>
              </el-form-item> -->
              <!-- <el-form-item class="submitRow">
                <el-button type="primary" @click="onSubmit"
                  >提交版权登记</el-button
                >
              </el-form-item> -->
              <el-form-item class="submitRow">
                <el-button type="primary" @click="prev">上一步</el-button>
                <el-button type="primary" @click="next">下一步</el-button>
              </el-form-item>
            </div>
          </el-form>
  </div>
</template>

<script>
import { queryExistOrderItems } from "@/api/EvidenceService";
import { queryObligeeList,queryEvidenceRight } from "@/api/ObligeeService";
import multipleSelect from "../../../../components/form/multipleSelect";
import choosePersonDialog from "../../../../components/form/choosePersonDialog";
import resultView from "../components/resultView";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../../mixins";
import constants from "@/utils/constants";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    multipleSelect,
    choosePersonDialog,
    resultView,
    ChinaAreaData,
    ElImageViewer
  },
  props: {
    stepForm: Object,
  },
  data() {
    return {
      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=register/evidence/files/`,
      uploadHeaders:{ 
        'Authorization': this.$store.getters.token,
        'Product-Mode': "READ",
        'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
        'Terminal-Type': "WEB",
        'Merchant-Id': "5"
      },
      constants,
      ownershipText: "",
      signatureTypeText: [],
      obtainTypeText: "",

      form: {
        ownership: "1",
        obtainType: "",
        authors: [
          {name:"",signatureType:"",signatureName:"",orders:1},
        ],
        obtainTypeDesc: {
          text:"",
        },
        ownershipPicture: {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": "",
          "additionalProp4": "",
        },
        rightHave: [],

      },
      ownershipChecked: false,
      isOpenViewer: false,
      rules: {
        ownership: [
          { required: true, message: "请选择权利权属", trigger: "blur" },
        ],
        obtainType: [
          { required: true, message: "请选择权利取得方式", trigger: "blur" },
        ],
        rightHave: [
          { required: true, message: "请选择权益拥有情况", trigger: "blur" },
        ],

      },
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,

      lastform: {
        type: Object,
      },
    };
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler, false)
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler, false)
  },
  methods: {
    beforeUnloadHandler(e) {
      e.returnValue = "离开此页面？" // 此处返回任意字符串，不返回null即可，不能修改默认提示内容
    },
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  created() {
    this.loadData();
    this.form = this.stepForm
    if(this.form.ownership){
      this.ownershipText = this.constants.ownershipOpt[this.form.ownership * 1 - 1].label;
    }else{
      this.form.ownership = '1';
      this.ownershipText = '个人作品';
    }
    if(this.form.authors[0].name){
      let arr = [];
      for(let i =0; i<this.form.authors.length; i++){
        arr.push(this.constants.signatureType[this.form.authors[i].signatureType * 1 - 1].label)
      }
      this.signatureTypeText = arr;
    }
    if(this.form.obtainType){
      this.obtainTypeText = this.constants.obtainType[this.form.obtainType * 1 - 1].label;
    }else{
      this.form.obtainType = '1';
      this.obtainTypeText = '原始';
    }
  },
  methods: {
    loadData() {
      queryExistOrderItems().then(({ data }) => {
        this.fileList = data;
      });
      queryObligeeList(1, 100).then(({ data }) => {
        this.obligees = data.list;
      });
    },
    calAmount() {
      let amount = 0;
      let registerConfig = this.evidencePaymentPrice.register;
      amount = registerConfig.amount * 1;

      this.form.orderAmount = amount;
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
    },
    valiIcon1(rule, value, callback){
					if (!this.form.ownershipPicture.additionalProp1) {
					  callback(new Error('请上传权利取得方式证明材料'));
					  return; 
					}
					callback();
		},
    valiIcon3(rule, value, callback){
					if (!this.form.ownershipPicture.additionalProp3) {
					  callback(new Error('请上传权利归属证明材料'));
					  return; 
					}
					callback();
		},
    // valiIcon4(rule, value, callback){
		// 			if (!this.form.ownershipPicture.additionalProp4) {
		// 			  callback(new Error('请上传权利保证书'));
		// 			  return;
		// 			}
		// 			callback();
		// },
    valiIcon4(rule, value, callback){
					if (!this.ownershipChecked) {
					  callback(new Error('请勾选权利保证书'));
					  return;
					}
					callback();
		},
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      if (!this.form.area) {
        callback(new Error("请选择区县"));
        return;
      }
      callback();
    },
    selectArea(data) {
      this.form.province = data.selectData.province.name;
      this.form.city = data.selectData.city.name;
      this.form.area = data.selectData.area.name;
    },
    selectArea2(data) {
      this.form.province2 = data.selectData.province.name;
      this.form.city2 = data.selectData.city.name;
      this.form.area2 = data.selectData.area.name;
    },
    chooseFile() {
      let files = [];
      for (let i = 0; i < this.form.evidenceFile.length; i++) {
        const element = this.form.evidenceFile[i];
        files.push(this.fileList[element * 1]);
      }
      this.form.files = files;
      this.calAmount();
    },

    uploadSuccess1(res, file) {
      this.form.ownershipPicture.additionalProp1 = res.downloadUrl;
    },
    uploadBefore1(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'; 
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isImage && isLt2M;
    },
    uploadSuccess2(res, file) {
      this.form.ownershipPicture.additionalProp2 = res.downloadUrl;
    },
    uploadBefore2(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'; 
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isImage && isLt2M;
    },
    uploadSuccess3(res, file) {
      this.form.ownershipPicture.additionalProp3 = res.downloadUrl;
    },
    uploadBefore3(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'; 
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isImage && isLt2M;
    },
    uploadSuccess4(res, file) {
      this.form.ownershipPicture.additionalProp4 = res.downloadUrl;
    },
    uploadBefore4(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'; 
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isImage && isLt2M;
    },
    
    
    chooseOwnership(index){
      // console.log("选择权利归属");
      this.ownershipText = this.constants.ownership[index].label;
      this.form.ownership = this.constants.ownership[index].value;
    },
    chooseSignatureType(index,typeIndex) {
      // console.log("选择权署名");
      // console.log("index",index);
      // console.log("typeIndex",typeIndex);
      this.signatureTypeText[typeIndex] = this.constants.signatureType[typeIndex].label
      this.$set(this.form.authors, index, {
          ...this.form.authors[index],
          signatureType: this.constants.signatureType[typeIndex].value,
      })
      
    },
    chooseObtainType(index){
      // console.log("选择权利取得方式 ");
      this.obtainTypeText = this.constants.obtainType[index].label;
      this.form.obtainType = this.constants.obtainType[index].value;
    },

    addAuthors(index){
      this.form.authors.push({"name":"","signatureType":"0","signatureName":"","orders":index+2})
      this.signatureTypeText[index+1] = ''
      // console.log("作者：",this.form.authors);
    },
    delAuthors(index){
      this.form.authors.splice(index,1)
    },

    // 勾选权利保证书，生成保证书图片
    ownershipChange(){
      if(this.ownershipChecked == true){
        let signature = '';
        for(let i in this.form.right){
          if(this.form.right[i].obligeeType == '1'){
            //  个人著作权人
            signature += this.form.right[i].userName + ","
          }else{
            // 企业著作权人
            signature += this.form.right[i].companyName + ","
          }
        }
        this.signature = signature.substring(0, signature.lastIndexOf(','));
        // console.log(this.signature);
        let param = {
          "signature": this.signature,
          "subject": this.form.subject,
        }
        queryEvidenceRight(param).then(({ data }) => {
          this.form.supportCode = data.supportCode;
          this.form.ownershipPicture.additionalProp4 = data.supportInfo.jpgUrl;
          this.ownershipCheckedStatus = true;
        });
      }
    },
    // 查看快照
    openViewer() {
      this.isOpenViewer = true
    },
    // 关闭图片预览
    closeViewer() {
      this.isOpenViewer = false
    },


    prev(){
        this.$emit('getnext',4)
    },
    async next(){
        try {
          if (!this.ownershipCheckedStatus) {
					  this.$message({
              type: 'warning',
              message: '请稍等,正在生成权利保证书！'
            });
					  return;
					}
          const formBaseResult = await this.$refs["form"].validate();
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          this.mixinsScrollView();
          return;
        }
        // console.log("lastform:",this.lastform);
        console.log(this.form);
        this.$emit('getnext',6,this.form)
    },

  },
};
</script>

<style lang='scss' scoped>
@import "../../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
}
.ownershipRow {
  margin-bottom: 0;
}
.ownershipWrap {
  position: relative;
  padding-top: 40px;
  /deep/ .el-radio-group {
    position: absolute;
    width: 200%;
    left: 0;
    top: 11px;
  }
  .tips {
    margin-top: 0;
    .red {
      color: #ff4d4f;
      vertical-align: middle;
    }
  }
}
// .tips {
//   margin-top: 20px;
// }
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}
.signatureName {
  .el-select,
  .el-input {
    width: calc(110% / 2.4)!important;
  }
  .el-select {
    margin-right: calc(20% / 2.4)!important;
  }
}
.Uploader2,.Uploader3 {
  font-size: 14px;
  width: 132px;
  height: 180px;
  text-align: center;
  background: #f9fafc;
  border-radius: 4px;
  border: 1px solid #c0ccda;
  position: relative;
  .avatar-uploader-icon{
    font-size: 28px;
    color: #8c939d;
    text-align: center;
    line-height: 178px;
  }
}
.Uploader2 img,.Uploader3 img{
  width: auto;
	max-width: 100%;
  height: auto;
  max-height: 100%;
  position: absolute;
  top:50%; 
  left:50%;
  transform: translate(-50%,-50%);
}
/deep/ .submitRow .el-form-item__content {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MainStepsForm .submitRow button {
  width: 46% !important;
}

.stepTitle{
  width: 100%;
  margin: 50px 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepTitle li {
  padding: 0 10px;
}
.stepTitle li span{
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 18, 22, 0.2);
  font-size: 16px;
  color: rgba(0, 18, 22, 0.2);

}
.stepTitle li p{
  color: rgba(0, 18, 22, 0.45);
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.stepTitle li.active span{
  border-color: #007DFF;
  color: #fff;
  background: #007DFF;
}
.stepTitle li.active p{
  color: #007DFF;
}
.ownershipPicture1 .tips{
  top: 45px;
}
</style>
